<template>
    <div class="container">

        <div class="title">
            Cancelación de clase
        </div>


        <div class="form-container">
            <form class="cancellation-form">
                <label>Estudiante:
                </label>
                <div>
                    {{ data.user.ui }}
                </div>
                <label>Motivo de Cancelación:
                </label>
                <textarea v-model="data.reason" placeholder="…Motivo de Cancelación…">
                    
                </textarea>
    
                
            </form>
            <hr>
            <button @click.stop="cancel_class()">Cancelar Clase</button>

        </div>
    
        <div class="policy-warning subtitle">
            Politica de Cancelación:
        </div>
        <ul>
            <li>
                Cancelación tardía:
                <ul>
                    <li>
                        La cancelación se realiza a menos de una hora de la clase programada.
                    </li>
                </ul>
            </li>
            <li>
                Cancelación a tiempo | Reagendación:
                <ul>
                    <li>
                        La cancelación se realiza más de una hora antes de la clase programada.
                    </li>
                </ul>
            </li>
        </ul>


    
    </div>
</template>



<script setup>
    import {onBeforeMount,onMounted,reactive,inject} from 'vue'
    import axios from 'axios'
    import {useStore} from 'vuex'
    import {useRoute} from 'vue-router'

    let store=useStore()
    let route=useRoute()

    let user=store.state.user

    let data=reactive({
        reason:''
    })

    data.user=user


    onBeforeMount(async ()=>{

        if (user.role.includes('Admin')){
            let user_id=route.query.user_id
            
            let response= await axios.post('/admin/get_user/',{user_id:user_id})
            user=response.data
            data.user=user
            
        }
        data.group_id=route.query.group_id
    })



    async function cancel_class(){


        if (data.reason==''){
            alert('Informacion faltante: motivo de cancelación')
            return
        }
        // group id is not defined. define in url query?
        let response=await axios.post('/students/cancellation/',{user_id:data.user.id,group_id:data.group_id,status:'cancelled-by-student'})
        window.location.href=`/student-dashboard?user_id=${user.id}`
    }



</script>



<style scoped>

    .container{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        background: var(--darkred);
        
    }

    .form-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-width: max-content;
        width: fit-content;
        max-width: 100vw;

        border-radius: 5px;
        border: var(--border);

        background: var(--red);

        padding: 1rem;
    }

    .cancellation-form{

        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        justify-content: center;



        gap: 15px 0px;
    }

    .cancellation-form label {
        text-align: end;

    }


    hr {
        width: 100%;
        margin: 1rem;
    }

    








</style>