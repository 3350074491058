<template>

<div class="title">Terms and Conditions for InterAct Speaking Center</div>

<div class="subtitle">General Terms</div>

<div class="TCcontainer">


    <ol type="I">
        <li>
            <div class="subtitle">Eligibility</div>
            <ol type="i">
                <li>
                    All users must be at least 18 years old to use the services of InterAct Speaking Center. Minors may only use the services under the supervision of a parent or legal guardian, who agrees to these terms on their behalf.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Account Responsibilities</div>
            <ol type="i">
                <li>
                    Users must provide accurate and complete information during registration and maintain the security of their account credentials. They are responsible for all activities occurring under their accounts.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">User Content</div>
            <ol type="i">
                <li>
                    By posting or sharing content on the platform, users grant InterAct a global, irrevocable license to use, reproduce, modify, and distribute the content for business and promotional purposes. All content must comply with applicable laws.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Modifications to Terms</div>
            <ol type="i">
                <li>
                    InterAct reserves the right to modify these terms and conditions at any time. Users will be notified of any significant changes. Continued use of the platform constitutes acceptance of the new terms.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Dispute Resolution</div>
            <ol type="i">
                <li>
                    The parties agree that any disputes, claims, or controversies arising out of or relating to this contract shall not be resolved through court litigation or binding arbitration. Instead, the parties will attempt to resolve any dispute through good-faith negotiation or mediation.
                </li>
                <li>
                    In the event of a dispute, the parties agree to first attempt to resolve the matter through direct negotiation between authorized representatives. If the dispute cannot be resolved through negotiation within [30 days], the parties will submit the dispute to mediation with a mutually agreed-upon mediator. The cost of mediation shall be shared equally by both parties
                </li>

            </ol>
        </li>

        <li>
            <div class="subtitle">Termination of Service</div>
            <ol type="i">
                <li>
                    InterAct reserves the right to terminate any user account or restrict access for any violation of the terms or misuse of the platform.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Data Privacy</div>
            <ol type="i">
                <li>
                    InterAct collects, processes, and stores personal data in accordance with applicable data protection laws, including the GDPR. All users must comply with relevant privacy laws.
                </li>
            </ol>
        </li>
    
    </ol>

</div>

<div class="subtitle">Tutor Terms</div>
<div class="TCcontainer">
    <ol type="I">
        <li>
            <div class="subtitle">Independent Contractor Status</div>
            <ol type="i">
                <li>
                    Tutors are classified as independent contractors, not employees. Tutors are responsible for their own taxes, insurance, and any expenses incurred while providing services. InterAct does not guarantee any minimum number of students or income.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Payment Terms</div>
            <ol type="i">
                <li>
                    Tutors are paid on days 5th and 20th of each month, provided they meet a minimum payment requirements (e.g., hoursheet validation). If this minimum is not met, payments are subject to delays and fee incursion. 
                </li>
                <li>
                    All payments are processed through third-party services. Any processing fees associated with payments to tutors will be borne by the tutor. It is the tutor's responsibility to cover these fees from their payment, and such fees will not be absorbed or reimbursed by the organization.
                </li>
                <li>
                    Payment methods accepted.
                    <ol type="a">
                        <li>Wise</li>
                        <li>Payoneer</li>
                        <li>Paypal</li>
                        <li>Crypto (BTC, ETH, XRP, other if applicable)</li>
                    </ol>
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Promotional Materials</div>
            <ol type="i">
                <li>
                    Tutors consent to the use of their names, images, voices, and other likenesses by InterAct for advertising and promotional purposes without additional compensation. InterAct retains all rights to any promotional material created using such likenesses.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Non-Exclusivity</div>
            <ol type="i">
                <li>
                    Tutors may offer their services on other platforms and work with other clients. InterAct also retains the right to engage with multiple service providers and promote other tutoring services.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Data Privacy for Tutors</div>
            <ol type="i">
                <li>
                    Tutors must protect all student data they access and comply with all relevant privacy laws, including not sharing data for purposes beyond providing tutoring services.
                </li>
            </ol>
        </li>
        <li>
            <div class="subtitle">Communications Consent</div>
            <ol type="i">
                <li>
                    By using the InterAct website, users consent to receive notifications regarding their classes and payments through SMS, WhatsApp, and email.
                </li>
            </ol>
        </li>
    </ol>

</div>

<div class="subtitle">Student Terms</div>

<div class="TCcontainer">

    <ol type="I">
        <li>
            <div class="subtitle">Payment and Refund Policy</div>
            <ol type="i">
                <li>
                    Students must pay in advance for lessons or subscriptions unless otherwise established in previous negotiations. Refunds may be requested within a specified period (e.g., 28 days) if no lessons or credits have been used.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Data Privacy for Students</div>
            <ol type="i">
                <li>
                    InterAct collects and processes student data solely for the purposes of providing educational services, and in accordance with its privacy policy. Student data will not be shared with third parties without consent, except as required by law.
                </li>
            </ol>
        </li>

        <li>
            <div class="subtitle">Communications Consent</div>
            <ol type="i">
                <li>
                    By using the InterAct website, users consent to receive notifications regarding their classes and payments through SMS, WhatsApp, and email.
                </li>
            </ol>
        </li>

        <!-- <li>
            <div class="subtitle"></div>
            <ol type="i">
                <li>

                </li>
            </ol>
        </li> -->

    </ol>

    By using InterAct Speaking Center, all users (students and tutors) agree to these terms and conditions. Failure to adhere to these terms may result in the termination of access to the platform.

</div>

</template>



<script setup>



</script>


<style scoped>

.TCcontainer{
    width: fit-content;
    max-width: 1000px;
    margin: 2rem;

}

li{
    text-align: justify;
}

</style>