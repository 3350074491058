<template>
  
  <div class="status-buttons">

    <button @click.stop="confirmation(data.group,data.user.id,'confirmed')" class="confirmed">Confirmed</button>
    <button @click.stop="confirmation(data.group,data.user.id,'cancelled-late')" class="cancelled-late">Cancelled Late</button>
    <button @click.stop="confirmation(data.group,data.user.id,'no-show')" class="no-show">No Show</button>
    <button @click.stop="confirmation(data.group,data.user.id,'cancelled-on-time')" class="cancelled-on-time">Cancelled On Time</button>
    <button @click.stop="confirmation(data.group,data.user.id,'rescheduled')" class="reschedule">Reschedule</button>
  
  </div>

</template>

<script setup>
  import {ref,reactive,inject,onUpdated} from 'vue'
  import axios from 'axios'
  
  let data=reactive()
  data=inject('status_data')








  async function confirmation(group,user_id,status){

    data.status=status
    // let response= await axios.post('/instructors/confirmation/',{group_id:group.id,user_id:user_id,status:status})
    // group=Object.assign(group,response.data)

    

  }




</script>



<style scoped>


.status-buttons{
  height:auto;
  width: fit-content;
  max-width: 100%;
  /* font-size:0px; */
  /* background:var(--black); */
  display:grid;
  align-items:center;
  justify-content: center;
  overflow:hidden;
  align-self:center;
  justify-self:center;

  transition: var(--transition);
  gap:10px;

  grid-template-areas:"confirmed cancelled-late no-show cancelled-on-time rescheduled ";

}





.confirmed{
  background:var(--darkgreen);
  grid-area:confirmed;
}
.cancelled-late{
  background:var(--red);
  grid-area:cancelled-late;

}
.no-show{
  background:var(--darkred);
  grid-area:no-show;
}

.cancelled-on-time{
  background:var(--lightblue);
  grid-area:cancelled-on-time;

}
.rescheduled{
  background:var(--gray);
  grid-area:rescheduled;

}















</style>