<template>
    <div class="edit-container" :class="{show_edit_window:data.show_edit_window,hide_edit_window:data.hide_edit_window}">
        <div @click.stop="close_edit_window()" class="title">{{data.edit_object.endpoint}} {{ data.edit_object.ui }}</div>
        <div @click.stop class="edit">
            <template v-if="data.edit_object">
                <table>
                    <tbody>
                        <template v-for="(value,key,index) in data.edit_object">
                                <tr>

                                    <td>
                                        {{ key }}:
                                    </td>
                                    <template v-if="typeof(value)=='object'">
                                        <td>
                                            <button @click.stop="show_edit_subobject_window(data.edit_object[key])">
                                                Edit {{ key }}
                                            </button>
                                        </td>
                                    </template>
                                    <template v-else-if="typeof(data.edit_object[key])=='boolean'">
                                        <td>
                                            <input @change.stop="patch(data.edit_object,key,data.edit_object[key])" v-model="data.edit_object[key]" type="checkbox">
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td>
                                            <input @change.stop="patch(data.edit_object,key,data.edit_object[key])" v-model="data.edit_object[key]">
                                        </td>
                                    </template>
                                </tr>

                        </template>
                    </tbody>
                </table>
                
            </template>
        </div>
    </div>
    <EditSubObject/>
</template>


<script setup>

    import {reactive,ref, inject} from 'vue'
    import axios from 'axios'
    import EditSubObject from '@/components/EditSubObject.vue'
    let data=inject('data')
    
    function close_edit_window(){
        data.hide_edit_window=true
        setTimeout(()=>{
            data.show_edit_window=false
        
        },500)



        setTimeout(() => {
            data.edit_object={}
            
        }, 500);

    }



    function patch(object,key,value){
        let response=axios.patch('/admin/patch/',{object:object,key:key,value:value})
        console.log(response.data)
        Object.assign(object,response.data)
    }



    async function show_edit_subobject_window(subobject){
        let response=await axios.post('/admin/get_object/',{object:subobject})
        Object.assign(subobject,response.data)
        data.edit_subobject=subobject
        data.show_edit_subobject_window=true
        data.hide_edit_subobject_window=false
        console.log(data.edit_subobject)
    }



</script>


<style scoped>


.edit-container{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 0;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: auto;
    background:var(--lightblue);
    color:var(--white);
    max-width:0;
    max-height:0;
}


.edit{
    display: flex;
    flex-direction: column;
    gap: 5px;
    widows: 100%;
}











.show_edit_window{

animation-name: show_edit_window;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */
/* animation-iteration-count: iteration-count; */
animation-direction: normal;
animation-fill-mode: forwards;

--height:80vh;
--width:100%;

--font-size:large;
--padding:5px;
--margin:5px;

}





@keyframes show_edit_window {
  0%{
    width:0%;
    max-width:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
  }

  100%{
    /* min-width:var(--width); */
    width:var(--width);
    max-width:var(--width);
    /* min-height:var(--height); */
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);

    
  }

}




.hide_edit_window{


animation-name: hide_edit_window;
animation-duration: var(--animation-time);
animation-timing-function: ease-in-out;
/* animation-delay: calc(var(--animation-time)*2); */

animation-direction: normal;
animation-fill-mode: forwards;
}




@keyframes hide_edit_window {
  0%{
    width:var(--width);
    max-width:var(--width);
    height:var(--height) ;
    max-height:var(--height);
    font-size:var(--font-size);
    padding:var(--padding);
    margin:var(--margin);
  }

  100%{
    width:0%;
    max-width:0%;
    height:0%;
    max-height:0%;
    font-size:0px;
    padding:0px;
    margin:0px;
  }



  
}





</style>


