<template>
    <div class='title'>
        Collection Report
    </div>

    <div class="collections">
        
        <table>
            <thead>
                <th>Group</th>
                <th>Student</th>
                <th>Payments</th>
                <!-- <th>Vacation Status</th> -->
                <th>Comments</th>
                <!-- <th>Days since last class</th> -->
                
            </thead>
            <tbody>
                <tr :class="{strong_urgent:student.collection_status>=3,urgent:student.collection_status==2,weak_urgent:student.collection_status==1,ok:student.collection_status==0,null:student.collection_status==-9,inactive:student.collection_status==-10, end:student.payment_method.includes('@end')}" v-for="student in collection.students">
                    <td>
                        <div class="group-container">
                            <template v-if="student.groups">
                                <template v-for="group in student.groups">
                                    <table>
                                        <tr>
                                            <template v-if="group.instructor">
                                                <template v-for="instructor in group.instructor">
                                                    <button @click.stop="show_edit_window(instructor)">
                                                        {{ instructor.ui }}
                                                    </button>
                                                </template>
                                            </template>
                                        </tr>
                                        <tr>
                                            <button @click.stop="show_edit_window(group)">
                                                {{ group.schedule }}
                                            </button>
                                        </tr>
                                        <button @click.stop="open_whatsapp(group.whatsapp)">{{ group.whatsapp }}</button>
                                    </table>    
                                </template>
                            </template>
                        </div>
                    </td>

                    <td>
                        <div class="student-container">
                            <button class="subtitle" @click.stop="show_edit_window(student)">
                                {{ student.ui }} 
                            </button>
                            <div @click.stop="send_to_student_dashboard(student.user.id)">DASHBOARD</div>
                            <div>{{ student.level }}</div>
                            <button @click.stop="open_whatsapp(student.user.whatsapp)">Student Whatsapp</button>
                        </div>
                    </td>
                    <td @click.stop="show_payment_window(student)">
                        <button>
                            <template v-if="student.payments">
                                <table>
                                    <template v-for="payment in student.payments">
                                        <tr class="payment" :class="{paid:payment.paid}">
                                            {{ payment.ui }} 
                                        </tr>
                                    </template>
            
                                </table>
        
                            </template>
                        </button>
                    </td>
    
                    <!-- <td>Vacation status here</td> -->
                    <td> {{ student.comments }} </td>
                    <!-- <td>Days since last class</td> -->
    
                </tr>
            </tbody>
        </table>

    </div>


    <Payments/>
    <Edit/>


</template>

<script setup>



import axios from 'axios'
import {reactive, onBeforeMount, provide} from 'vue'
import Payments from '@/components/Payments.vue'
import Edit from '@/components/Edit.vue'


let collection=reactive({
    students:[]
})
let data=reactive({
    payments_student:{},
    student_payments:[],
})

provide('data',data)
onBeforeMount(async ()=>{
    
    let response=await axios.post('/collection/get_collection/')
    collection.students=response.data
    console.log(collection)
})

async function show_payment_window(student){
    let payments=await axios.post('/payments/get_payments/',{student_id:student.id})
    let instructor_response=await axios.post('/payments/get_plain_instructors/')
    let student_response=await axios.post('/payments/get_plain_students/',{student_id:student.id})


    data.student_payments=[]


    data.emergency_group={}
    
    data.payment_instructors=instructor_response.data
    data.payment_students=student_response.data
    data.student_payments=payments.data

    data.payments_student=student
    data.show_payments_window=true
    data.hide_payments_window=false


}



async function show_edit_window(object){
    
    let response=await axios.post('/admin/get_object/',{object:object})
    Object.assign(object,response.data)
    data.edit_object=object
    data.show_edit_window=true
    data.hide_edit_window=false

}

function open_whatsapp(whatsapp_link){
    var link = window.open('https://'+whatsapp_link, '_blank');
    link.focus();

}


function send_to_student_dashboard(student_id){
        window.open('/student-dashboard?user_id='+student_id, '_blank');

    }



</script>



<style scoped>


    .collections{
        width: 100%;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        overflow:auto;
    }

    

    .group-container{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }

    .student-container{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }

    .student_dashboard{
        color:var(--white);
        
    }



    .strong_urgent{
        background: var(--darkred);
    }


    .urgent{
        background: var(--red);
    }
    .weak_urgent{
        background: var(--darkyellow);
    }
    .ok{
        background: var(--darkgreen);
    }

    .null{
        background:var(--white);
        color:var(--black);
    }

    .inactive{
        background: var(--gray);
        color: var(--white);
    }

    .payment{
        background: var(--red);
        color:var(--white);
    }


    .paid{
        background: var(--green);
        color:var(--white);
    }

    .end{
        border: var(--end-border);
    }



</style>